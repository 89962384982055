import React from 'react';
import PropTypes from 'prop-types';

import { AngleLeftLightIcon } from '../../../../shared/icons/icons';
import Link from '../../../link/link';

import './breadcrumbs.scss';

function Breadcrumbs(props) {
  const { className, crumbs } = props;

  return Array.isArray(crumbs) && crumbs.length > 0 ? (
    <div className={className} styleName="container">
      <ol styleName="crumbs">
        {crumbs.map((crumb, idx) => (
          <li key={`bc-${idx}`} styleName="crumb">
            {crumb.url ? (
              <Link className="dt-link" styleName="crumb-link" to={crumb.url}>
                <AngleLeftLightIcon
                  className={`${className}__link-icon`}
                  styleName="crumb-arrow"
                />
                {crumb.name}
              </Link>
            ) : (
              <span>{crumb.name}</span>
            )}
          </li>
        ))}
      </ol>
    </div>
  ) : null;
}

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string
    })
  ).isRequired
};

export default Breadcrumbs;
